// Import library functions for theme creation.
@use "@angular/material" as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dff-mat-tabs-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: mat.get-theme-color($theme, primary, default);
    $accent: mat.get-theme-color($theme, accent, default);
    $warn: mat.get-theme-color($theme, warn, default);

    .mat-tab-group.button-toggle {
        .mat-tab-header {
            border-bottom: none;

            .mat-tab-label {
                opacity: 1;
            }

            .mat-tab-label:first-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            .mat-tab-label:last-child {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            .mat-ink-bar {
                display: none;
            }
        }

        &.mat-primary {
            @include _mat-tab-label($primary);
        }

        &.mat-accent {
            @include _mat-tab-label($accent);
        }

        &.mat-warn {
            @include _mat-tab-label($warn);
        }
    }
}

@mixin _mat-tab-label($color) {
    .mat-tab-label {
        border: 1px solid mat-color($color);
    }

    .mat-tab-label-active {
        background-color: mat-color($color) !important;
        color: mat-color($color, default-contrast);
    }
}
