// Import library functions for theme creation.
@use "@angular/material" as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dff-mat-button-toggle-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: mat.get-theme-color($theme, primary, default);
    $accent: mat.get-theme-color($theme, accent, default);
    $warn: mat.get-theme-color($theme, warn, default);

    $primary-contrast: mat.get-theme-color($theme, primary, default-contrast);
    $accent-contrast: mat.get-theme-color($theme, accent, default-contrast);
    $warn-contrast: mat.get-theme-color($theme, warn, default-contrast);

    .mat-button-toggle-group[color="primary"] .mat-button-toggle-checked {
        color: $primary-contrast;
        background-color: $primary;
    }

    .mat-button-toggle-group[color="accent"] .mat-button-toggle-checked {
        color: $accent-contrast;
        background-color: $accent;
    }

    .mat-button-toggle-group[color="warn"] .mat-button-toggle-checked {
        color: $warn-contrast;
        background-color: $warn;
    }
}
