// Import library functions for theme creation.
@use "@angular/material" as mat;

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin dff-mat-slide-toggle-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $primary: mat.get-theme-color($theme, primary, default);
    $accent: mat.get-theme-color($theme, accent, default);
    $warn: mat.get-theme-color($theme, warn, default);

    $primary-contrast: mat.get-theme-color($theme, primary, default, 0.54);
    $accent-contrast: mat.get-theme-color($theme, accent, default, 0.54);
    $warn-contrast: mat.get-theme-color($theme, warn, default, 0.54);

    .mat-slide-toggle.always-colored[color="primary"] {
        .mat-slide-toggle-thumb {
            background-color: $primary;
        }

        .mat-slide-toggle-bar {
            background-color: $primary-contrast;
        }
    }

    .mat-slide-toggle.always-colored:not([color]),
    .mat-button-toggle-group[color="accent"] {
        .mat-slide-toggle-thumb {
            background-color: $accent;
        }

        .mat-slide-toggle-bar {
            background-color: $accent-contrast;
        }
    }

    .mat-button-toggle-group[color="warn"] {
        .mat-slide-toggle-thumb {
            background-color: $warn;
        }

        .mat-slide-toggle-bar {
            background-color: $warn-contrast;
        }
    }
}
