@use "@angular/material" as mat;
@use "./components/dff-mat-button-toggle-theme.scss" as dffButtonToggle;
@use "./components/dff-mat-slide-toggle-theme.scss" as dffSlideToggle;
@use "./components/dff-mat-tabs-theme.scss" as dffTabs;

@include mat.core();

@mixin theming($theme, $typography: null) {
    @include mat.all-component-themes($theme);
    @if $typography {
        @include mat.angular-material-typography($typography);
    }

    @include dffSlideToggle.dff-mat-slide-toggle-theme($theme);
    @include dffButtonToggle.dff-mat-button-toggle-theme($theme);
    @include dffTabs.dff-mat-tabs-theme($theme);
    //@include dffTabs.theme($theme);

    :root {
        $primary: mat.get-theme-color($theme, primary, default);
        $accent: mat.get-theme-color($theme, accent, default);
        $primary-contrast: mat.get-theme-color($theme, primary, default-contrast);
        $accent-contrast: mat.get-theme-color($theme, accent, default-contrast);

        --primary-background-color: $primary;
        --primary-color: $primary-contrast;
        --accent-background-color: $accent;
        --accent-color: $accent-contrast;

        --mat-dialog-container-max-width: 1080px;
        --mat-dialog-container-small-max-width: 90vw;
        --mat-expansion-header-collapsed-state-height: 60px;
    }
}
